import { createAsyncThunk } from "@reduxjs/toolkit";

export const updateOptInListCustomer = createAsyncThunk(
  "optInListCustomer/updateOptInListCustomer",
  async ({ customerId, campaignId, workspaceId }, thunkAPI) => {
    const optInListCustomerData = {
      customerId,
      campaignId,
      state: "SUBSCRIBED"
    };

    const response = await fetch(`${process.env.BACKEND_API}/graphql`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        query: `
        mutation UpdateOptInListCustomer($workspaceId: ID!, $optInListCustomerData: OptInListCustomerInput!) {
          updateOptInListCustomer(workspaceId: $workspaceId, optInListCustomerData: $optInListCustomerData) {
            id
            state
            workspaceId
            optInListId
            customerId
            createdAt
            updatedAt
          }
        }
      `,
        variables: {
          workspaceId,
          optInListCustomerData
        }
      })
    });

    const { data, errors } = await response.json();

    if (errors) {
      throw new Error(errors.map(item => item.message).join("\n"));
    }

    return data;
  }
);
