import React, { useEffect } from "react";
import { connect } from "react-redux";
import { trackingGA } from '@merchx-v2/helpers'
import { SEO } from 'components'
import { useSiteMetadata } from 'hooks/use-site-metadata';
import Smile from "assets/smile.svg";
import * as actions from "state/SubscribePage/actions";
import * as styles from "./SubscribePage.module.scss";

const getURLParams = (url) => {
  const args = /^[?#]/.test(url) ? url.slice(1) : url;
  return args.split("&").reduce((params, param) => {
    const [key, value] = param.split("=");
    params[key] = value ? decodeURIComponent(value.replace(/\+/g, " ")) : "";
    return params;
  }, {});
};

const SubscribePage = ({ updateOptInListCustomer }) => {
  const { landing } = useSiteMetadata()

  useEffect(() => {
    const pathSearch = window.location.search;
    trackingGA()
    if (pathSearch) {
      const params = getURLParams(pathSearch);

      const customerId = params.customerId || "";
      if (customerId) {
        updateOptInListCustomer({ customerId, campaignId: landing.CAMPAIGN.id, workspaceId: landing.WORKSPACE_ID });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.subscribe}>
      <SEO pathname='/subscribe' />
      <div className={styles.page}>
        <div className={styles.title}>You're In!</div>
        <div className={styles.bodyContainer}>
          <div>Welcome to our VIP list.</div>
          <div>You'll now receive in your inbox weekly:</div>
          <ul className={styles.list}>
            <li className={styles.item}>
              More free gifts (t-shirts, hoodies, crewnecks, tote bags, etc.)
            </li>
            <li className={styles.item}>
              Special deals on never-before-seen apparel hand designed by other
              fans
            </li>
          </ul>
          <p>
            If you don't see these emails in your inbox, be sure to check your
            promotions or spam folder!
          </p>
        </div>
        <div className={styles.thx}>
          Thank you <img src={Smile} alt="Smile" />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  updateOptInListCustomer: actions.updateOptInListCustomer,
};

export default connect(undefined, mapDispatchToProps)(SubscribePage);
